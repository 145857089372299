export const EMPLOYEE_YEARS_OF_EXPERIENCE = {
  LESS_1_YEAR: "Menos de 1 año",
  LESS_2_YEARS: "Menos de 2 años",
  LESS_3_YEARS: "Menos de 3 años",
  MORE_3_YEARS: "Más de 3 años",
  MORE_5_YEARS: "Más de 5 años",
  MORE_10_YEARS: "Más de 10 años",
  NO_EXPERIENCE: "Sin experiencia",
} as const;

export type TEmployeeExperience =
  typeof EMPLOYEE_YEARS_OF_EXPERIENCE[keyof typeof EMPLOYEE_YEARS_OF_EXPERIENCE];

export const EMPLOYEE_BUSINESS_COUNTRY = {
  ES: "es",
  FR: "fr",
} as const;

export type TEmployeeBusinessCountry =
  typeof EMPLOYEE_BUSINESS_COUNTRY[keyof typeof EMPLOYEE_BUSINESS_COUNTRY];

export const EMPLOYEE_DRIVING_LICENSE = {
  NO: "NO",
  YES: "SI",
} as const;

export type TEmployeeDrivingLicense =
  typeof EMPLOYEE_DRIVING_LICENSE[keyof typeof EMPLOYEE_DRIVING_LICENSE];

export const EMPLOYEE_STATUS = {
  NOT_VALIDATED: "Sin Validar",
  VALIDATED: "Validado",
  ACTIVE: "Activo",
  VALIDATED_PLUS: "Validado Plus",
  DISCARDED: "Descartado",
} as const;

export type TEmployeeStatus =
  typeof EMPLOYEE_STATUS[keyof typeof EMPLOYEE_STATUS];

export const EMPLOYEE_FELIZ_VITA_STATUS = {
  NOT_VALIDATED: "Sin Validar",
  VALIDATED: "Validado",
  ACTIVE: "Activo",
  VALIDATED_PLUS: "Validado Plus",
  IN_PROCESS: "En proceso",
  DISCARDED: "Descartado",
};

export type TEmployeeFelizVitaStatus =
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.NOT_VALIDATED
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.ACTIVE
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.VALIDATED_PLUS
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.IN_PROCESS
  | typeof EMPLOYEE_FELIZ_VITA_STATUS.DISCARDED;

export const EMPLOYEE_EDUCATION_LEVEL = {
  BASIC: "10_Nivel basico",
  SECONDARY: "20_Estudios secundarios",
  POST_SECONDARY: "30_Estudios post secundarios",
  WITHOUT_STUDIES: "00_Sin Estudios",
} as const;

export type TEmployeeEducationLevel =
  typeof EMPLOYEE_EDUCATION_LEVEL[keyof typeof EMPLOYEE_EDUCATION_LEVEL];

export const EMPLOYEE_LANGUAGE = {
  ES: "Castellano",
  CA: "Catalán",
  EU: "Vasco",
  GL: "Gallego",
  FR: "Francés",
  EN: "Inglés",
  IT: "Italiano",
  PT: "Portugués",
  RO: "Rumano",
  AR: "Arabe",
  RU: "Ruso",
  DE: "Alemán",
  NL: "Holandés",
} as const;

export type TEmployeeLanguage =
  typeof EMPLOYEE_LANGUAGE[keyof typeof EMPLOYEE_LANGUAGE];

export const EMPLOYEE_GENDER = {
  MAN: "Hombre",
  WOMAN: "Mujer",
} as const;

export type TEmployeeGender =
  typeof EMPLOYEE_GENDER[keyof typeof EMPLOYEE_GENDER];

export const EMPLOYEE_OWNS_VEHICLE = {
  NO: "No",
  YES: "Si",
} as const;

export type TEmployeeOwnsVehicle =
  typeof EMPLOYEE_OWNS_VEHICLE[keyof typeof EMPLOYEE_OWNS_VEHICLE];

export const EMPLOYEE_WORK_REGIME = {
  REEH: "Régimen Especial de Empleados del Hogar (REEH)",
  SAD: "Régiman General (SAD)",
} as const;

export const EMPLOYEE_AVAILABILITY = {
  AVAILABLE: "available",
  WORKING: "working",
} as const;

export type TEmployeeWorkRegime =
  typeof EMPLOYEE_WORK_REGIME[keyof typeof EMPLOYEE_WORK_REGIME];

export type TGetEmployeeItemUserFavorite = {
  id: string;
  candidateId?: string | null;
  userId?: string | null;
  note?: string | null;
  createdAt: string;
  updatedAt: string;
};

export const EMPLOYEE_TIME_SLOT = {
  MORNING: "Mañana",
  NOON: "Mediodía",
  AFTERNOON: "Tarde",
};

export type TEmployeeTimeSlot =
  typeof EMPLOYEE_TIME_SLOT[keyof typeof EMPLOYEE_TIME_SLOT];

export const EMPLOYEE_SCORE = {
  EXCELLENT: "25",
  GOOD: "15"
} as const;

export type TEmployeeScore =
  typeof EMPLOYEE_SCORE[keyof typeof EMPLOYEE_SCORE];

export type TGetEmployeesCollectionItem = {
  id: string;
  A_os_de_experiencia?: TEmployeeExperience;
  Apellidos?: string;
  Apellidos_2?: string;
  Business_Country: TEmployeeBusinessCountry;
  Carnet_de_conducir?: TEmployeeDrivingLicense;
  Ciudad?: string;
  Codigo_postal?: string;
  Company?: string;
  DNI_NIE?: string;
  Direccion?: string;
  Estado_Cuideo: TEmployeeStatus;
  Estado_FV: TEmployeeFelizVitaStatus;
  Externa_entre_semana?: boolean;
  Externa_fin_de_semana?: boolean;
  Fecha_de_nacimiento?: string;
  Franja_horaria?: TEmployeeTimeSlot[];
  Interna_entre_semana?: boolean;
  Interna_fin_de_semana?: boolean;
  Latitud?: string;
  Longitud?: string;
  Name?: string;
  Nivel_formativo?: TEmployeeEducationLevel;
  No_iniciar_bot_hasta?: string;
  Nombre?: string;
  Numero?: string;
  Otros_Idiomas?: TEmployeeLanguage[];
  Patolog_as_en_las_que_tiene_experiencia?: string[];
  Phone?: string;
  Phone_SMS_Error?: boolean;
  Provincia?: string;
  Qu_tareas_quieres_o_puedes_realizar: string[];
  R_gimen_de_trabajo?: TEmployeeWorkRegime[];
  Sexo?: TEmployeeGender;
  Trabajador_SAD?: boolean;
  Trabajando_Hasta?: string;
  Titulaci_n_oficial_SAD?: string[];
  Veh_culo_propio?: TEmployeeOwnsVehicle;
  Vencimiento_NIE?: string;

  age?: number;
  avatar?: string;
  avatarUrl?: string;
  avatarCreatedAt?: string;
  avatarUploadedByUser?: boolean;
  botAvailable?: boolean;
  createdAt: string;
  favoritedBy?: TGetEmployeeItemUserFavorite[];
  interviewStatus?: string;
  lastActivityTime?: string;
  numCommunicationIntents?: number;
  numCommunicationIntentsLastDay?: number;
  numContractsWorked?: number;
  numDaysSinceEnteredValuationProcess?: number;
  numDaysSinceLastActivity?: number;
  numDaysSinceLastApply?: number;
  numDaysSinceLatestEndContract?: number;
  numDaysWorked?: number;
  numOpenCommunicationIntents?: number;
  numOpenValuationProcesses?: number;
  phoneWaStatus?: string;
  phoneWaStatusAt?: string;
  hasWhatsapp?: boolean;
  substitutions?: boolean;
  zohoId?: string;
  contractedFv?: boolean;
};

export type TGetEmployeeItemExperience = {
  Patolog_as_tratadas?: string[];
  Mostrar_Ref_Ficha?: boolean;
  Funciones_y_tareas_realizadas?: string;
  Empresa_o_familia?: string;
  Telefono_del_contacto?: string | null;
  Comentarios_referencias?: string;
  id: string;
  Nombre_del_contacto?: string;
};

export type TGetEmployeeItemFormation = {
  Titulo_valido_en_Espa_a?: string | null;
  Nombre_escuela_centro?: string;
  Nombre_del_curso?: string;
};

export type TGetEmployeeItem = TGetEmployeesCollectionItem & {
  Experiencia_y_referencias: TGetEmployeeItemExperience[];
  Formaciones: TGetEmployeeItemFormation[];
};
